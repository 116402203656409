<template>
    <div>
        <div class="row mb-4 mt-2">
            <div class="col-4">
                <KCButton
                    :dropdown-items="pageNavigationOptions"
                    variant="page-specific"
                    @dropdown-item:click="navigationSelection($event)"
                >
                    Page Navigation
                </KCButton>
            </div>

            <div class="col">
                <PDCollapseAllButton @collapse-all="collapseAllToggle" />
            </div>
        </div>

        <div
            v-for="component in displayedPolicyInfoSections"
            :key="`${component}Section`"
            class="mb-4"
        >
            <KCCard
                :id="component"
                :has-accent-bar="false"
                :b-card-props="{
                    'header-class': { 'header-body-hide': collapsedSections.includes(component) },
                    'body-class': { 'hide-card-body': collapsedSections.includes(component) }
                }"
            >
                <template #title>
                    {{ startCase(camelCase(component)) }}
                </template>

                <template #actions>
                    <KCFloatingActionButton
                        data-cy="fabCollapse"
                        class="ml-2"
                        type="collapse"
                        :active="collapsedSections.includes(component)"
                        @click="handleCollapse(component)"
                    />
                </template>

                <BCollapse
                    :visible="!collapsedSections.includes(component)"
                >
                    <component
                        :is="policyDataCardsMap[component]"
                        :in-editor="false"
                        :policy="policy"
                        :policy-url="policyUrls[component]"
                        :actions="[{text: 'Pending Changes', value: '1'}, {text: 'Change effective date', value: '2'}]"
                        @remove="removePolicyInfoSection"
                    />
                </BCollapse>
            </KCCard>
        </div>
    </div>
</template>

<script>
    import KCButton from '@imt/vue-kit-car/src/components/Button.vue';
    import KCCard from '@imt/vue-kit-car/src/components/Card.vue';
    import KCFloatingActionButton from '@imt/vue-kit-car/src/components/FloatingActionButton.vue';
    import camelCase from 'lodash/camelCase';
    import startCase from 'lodash/startCase';
    import Vue from 'vue';
    import VueScrollTo from 'vue-scrollto';
    import { mapState } from 'vuex';

    import PDCardAmendment from '@/components/PDCards/PDCardAmendment.vue';
    import PDCardBilling from '@/components/PDCards/PDCardBilling.vue';
    import PDCardCurrentTerm from '@/components/PDCards/PDCardCurrentTerm.vue';
    import PDCardPriorTerms from "@/components/PDCards/PDCardPriorTerms.vue";
    import PDCollapseAllButton from '@/components/PDCards/PDCollapseAllButton.vue';

    Vue.use(VueScrollTo, {
        duration: 300,
        easing: 'ease',
        offset: 0,
        force: true,
        cancelable: true,
        onStart: false,
        onDone: false,
        onCancel: false,
        x: false,
        y: true
    });

    export default {
        name: 'PDInfoCards',
        components: {
            KCButton,
            PDCollapseAllButton,
            PDCardBilling,
            KCFloatingActionButton,
            KCCard,
            PDCardCurrentTerm,
            PDCardAmendment,
            PDCardPriorTerms
        },
        props: {
            // This should be a string array of the names of the WFE policy info cards that should display
            policyInfoSections: {
                type: Array,
                default: () => [
                    'activeTerm',
                    'amendment',
                    'billing',
                    'claims',
                    'policyholderDocuments',
                    'otherInsureds',
                    'lossInformation',
                    'priorTerms'
                ]
            },
            policy: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                collapsedSections: [],
                collapseAllKey: false,
                removedPolicyInfoSections: []
            };
        },
        computed: {
            displayedPolicyInfoSections() {
                return this.policyInfoSections.filter(section => (!this.removedPolicyInfoSections.includes(section) && this.policyDataCardsMap[section]));
            },
            policyDataCardsMap() {
                return {
                    'activeTerm': PDCardCurrentTerm,
                    'amendment': PDCardAmendment,
                    'priorTerms': PDCardPriorTerms,
                    'billing': PDCardBilling
                };
            },
            pageNavigationOptions() {
                return this.displayedPolicyInfoSections.map(section => ({
                    text: startCase(section),
                    value: section
                }));
            },
            policyUrls() {
                return {
                    'activeTerm': `${process.env.VUE_APP_ADMIN_URL_CMS}/${this.policySystem?.url}`
                };
            },
            ...mapState('toolbox', [
                'policySystem'
            ]),
        },
        watch: {
            collapseAllKey(newValue) {
                if (newValue) {
                    Object.values(this.displayedPolicyInfoSections).forEach((section) => {
                        if (!this.collapsedSections.includes(section)) {
                            this.collapsedSections.push(section);
                        }
                    });
                } else {
                    this.collapsedSections.splice(0, this.collapsedSections.length);
                }
            },
        },
        methods: {
            collapseAllToggle(value) {
                this.collapseAllKey = value;
            },
            camelCase,
            handleCollapse(section) {
                if (this.collapsedSections.includes(section)) {
                    this.collapsedSections.splice(this.collapsedSections.findIndex(name => name === section), 1);
                } else {
                    this.collapsedSections.push(section);
                }
            },
            navigationSelection($event) {
                this.$scrollTo(`#${camelCase($event.target.innerText.trim().toLowerCase())}`);
            },
            startCase,
            removePolicyInfoSection(value) {
                this.removedPolicyInfoSections.push(value);
            }
        },
    };
</script>

<style
    lang="sass"
>
    .hide-card-body
        padding-top: 0 !important
        padding-bottom: 0 !important
        transition: padding 300ms

    .header-body-hide
        border-bottom-color: transparent !important
        transition: border-bottom-color 800ms
</style>
