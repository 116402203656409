<template>
    <div
        v-if="Object.entries(billingAccount).length"
    >
        <div
            v-for="(item, index) in fields"
            :key="index"
            class="row col-12"
        >
            <label
                :id="`${item.key}Label`"
                :data-cy="`${item.key}Label`"
                :for="`${item.key}`"
                :class="'font-weight-bold col-4'"
            >
                {{ item.label }}
            </label>
            <span
                :id="`${item.key}`"
                class="col-8"
            >
                {{ item.value }}
            </span>
        </div>
        <KCButton
            variant="secondary"
            class="mt-3"
            @click="goToLegacyBilling"
        >
            Go to billing
        </KCButton>
    </div>
    <div v-else>
        <div v-if="loading">
            <KCLoadingIndicator
                v-if="loading"
                :loading="loading"
            />
        </div>
        <div
            v-else
            id="billingNotFound"
        >
            This billing account does not exist
        </div>
    </div>
</template>

<script>
    import KCButton from '@imt/vue-kit-car/src/components/Button.vue';
    import KCLoadingIndicator from '@imt/vue-kit-car/src/components/KCLoadingIndicator.vue';
    import dateMixin from '@imt/vue-toolbox/src/mixins/date';
    import utils from '@imt/vue-toolbox/src/utils';
    import {mapActions} from 'vuex';

    export default {
        name: 'PDCardBilling',
        components: {
            KCButton,
            KCLoadingIndicator,
        },
        mixins: [
            dateMixin,
        ],
        props: {
            policy: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                expanded: true,
                billingAccount: {},
                loading: true,
                policyGroup: {}
            };
        },
        computed: {
            fields() {
                return [
                    {
                        key: 'full_name',
                        label: 'Billing Name',
                        value: this.billingAccount.fullName || ''
                    },
                    {
                        key: 'address',
                        label: 'Billing Address',
                        value: this.getBillingAddress
                    },
                    {
                        key: 'payment_method',
                        label: 'Bill Plan',
                        value: this.billingAccount.paymentMethod || ''
                    },
                    {
                        key: 'balance',
                        label: 'Account Balance',
                        value: this.formattedBalance,
                    },
                    {
                        key: 'current_due',
                        label: 'Minimum Due',
                        value: this.formattedCurrentDue
                    },
                    {
                        key: 'due_date',
                        label: 'Date Due',
                        value: this.formattedDueDate
                    },
                ];
            },
            formattedBalance() {
                const balance = this.billingAccount.balance || 0;

                return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(balance);
            },
            formattedCurrentDue() {
                const CurrentDue = this.billingAccount.currentDue;

                return CurrentDue ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(CurrentDue) : 'N/A';
            },
            getBillingAddress() {
                return [
                    this.billingAccount?.streetLine1,
                    this.billingAccount?.streetLine2,
                    this.billingAccount?.city,
                    this.billingAccount?.state,
                    this.billingAccount?.zipCode
                ].filter(Boolean).join(', ');
            },
            formattedDueDate() {
                return this.billingAccount.dueDate
                    ? this.dateFormatted(this.billingAccount.dueDate,'MM/DD/YYYY')
                    : 'MM/DD/YYYY';
            },
        },
        async created() {
            try {
                this.policyGroup = await this.fetchPolicyGroup({
                    id: this.policy.group.id
                });

                const accountNumber = this.policyGroup.accountNumber;

                if (accountNumber) {
                    this.billingAccount = await this.getBillingAccount({
                        accountNumber: accountNumber
                    });
                }
            } catch (error) {
                utils.console.log(error);
            } finally {
                this.loading = false;
            }
        },
        methods: {
            ...mapActions('policy', [
                'getBillingAccount',
                'fetchPolicyGroup'
            ]),
            goToLegacyBilling() {
                const url = `https://dev.legacy.imtins.com/imtonline/billing11/BillingEngine.pl?load=BillingAcctSummary&section=summary&account=${this.policyGroup.accountNumber}/`;
                window.open(url, '_blank');
            },
        }
    };
</script>
