<template>
    <KCButton
        class="float-right"
        variant="page-specific"
        @click="collapseAllToggle"
    >
        <span v-if="collapseAll">
            Expand All
            <FontAwesomeIcon :icon="['fas', 'expand-alt']" />
        </span>

        <span v-else>
            Collapse All
            <FontAwesomeIcon :icon="['fas', 'compress-alt']" />
        </span>
    </KCButton>
</template>

<script>
    import KCButton from '@imt/vue-kit-car/src/components/Button.vue';

    export default {
        name: 'PDCollapseAllButton',
        components: {
            KCButton
        },
        data() {
            return {
                collapseAll: false,
            };
        },
        methods: {
            collapseAllToggle() {
                this.collapseAll = !this.collapseAll;
                this.$emit('collapse-all', this.collapseAll);
            }
        }
    };
</script>
