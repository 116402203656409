<template>
    <KCMenu
        class="policy-data-menu"
        data-cy="navigation"
    >
        <KCMenuItem>
            <!-- TODO: hardcoding agency header for now -->
            Jones Insurance 1569039
        </KCMenuItem>

        <!-- TODO: this will need to be a v-for to loop through policyGroups -->
        <KCMenuItem>
            <!-- TODO: this will need to be updated to be a policyGroup.accountNumber when policyGroups todos are done -->
            Account {{ latestPolicyVersion.accountNumber || '' }}
            <template #dropdown-items>
                <!-- TODO: need to add a slot to KCMenuDropdown so that Account Summery doesn't display as a link -->
                <KCMenuDropdownItem>
                    Account Summary
                </KCMenuDropdownItem>
                <!-- TODO: this will need to be a v-for to display all policies that come from each policyGroup -->
                <KCMenuDropdownItem
                    :to="{name: 'policy', params: policy.number}"
                >
                    {{ policySystemTitle }} {{ policy.number }}
                </KCMenuDropdownItem>
            </template>
        </KCMenuItem>
    </KCMenu>
</template>

<script>
    import KCMenu from '@imt/vue-kit-car/src/components/layout/Menu.vue';
    import KCMenuDropdownItem from '@imt/vue-kit-car/src/components/layout/MenuDropdownItem.vue';
    import KCMenuItem from '@imt/vue-kit-car/src/components/layout/MenuItem.vue';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        components: {
            KCMenu,
            KCMenuItem,
            KCMenuDropdownItem,
        },
        props: {
            // TODO: once policy groups and versions have been added to backend, we'll want to make this accept them as a prop, for now we are just displaying a single policy
            // policyGroups: {
            //     type: Array,
            //     required: true
            // },
            policy: {
                type: Object,
                required: true
            },
            latestPolicyVersion: {
                type: Object,
                required: true
            },
        },
        computed: {
            policySystemTitle() {
                return this.formattedPolicySystems.find((policySystem) => policySystem.id === this.latestPolicyVersion.policySystemId)?.title;
            },
            ...mapGetters('toolbox', [
                'formattedPolicySystems'
            ])
        },
        methods: {
            ...mapActions('toolbox', [
                'fetchPolicySystems'
            ])
        }
    };
</script>

<style
    lang="sass"
    scoped
>
    .policy-data-menu
        border-left: 1px solid var(--kc-gray-300)
</style>
